/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
// Load core variables and mixins
// --------------------------------------------------

.flex-wrap { 
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@import "/bootstrap/bootstrap.less";
@import "variables.less";
@import "slick.less";
@import "video-js.less";
@import "videos-page.less";
@import "video-teaser.less";

//
// Custom CSS
// --------------------------------------------------

// RUC-US-2021-0238v6

/* 
	Individual Fixes 
	Headers
	Text
	Repeated Elements

*/
/* Individual Fixes */
.d-desktop {
  display: none;

  @media (min-width: @screen-lg-desktop) {
    display: block;
  }
}
.d-mobile {
  display: none;

  @media (max-width: @screen-md-max) {
    display: block;
  }
}

sub {
  top: -0.25em;
}
.clear {
  clear: both;
}
.d-block {
  display: block;
}
.ml-auto {
  display: block;
  margin: 0 auto;
}
.fl-none {
  float: none;
}
.space-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.align-self-center {
  -ms-flex-item-align: center;
      align-self: center;
}
.align-self-end {
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.page-content.banner {
  border-bottom: 1px solid;
}
.page-content.banner .container.center-block {
  margin: 0 auto;
}
.page-content.banner.home {
  height: 540px;
  background-size: auto 250px;
  background-position: top center;
  background-repeat: no-repeat;
  background-image: url('/wp-content/uploads/Ruconest-HCP-Site-10th-Anniversary-Homepage-Hero-Banner.jpg');
}
.page-content.banner.home.dark-hcp {
  /* background-image: url('../../../assets/img/2022/patient/21-PHA-10478-global-patient-header.jpg'); */
}
@media (max-width: 767px) {
  .page-content.banner.home.dark-hcp {
    /* background-image: url('../../../assets/img/2022/patient/21-PHA-10478-global-mobile-patient-header.jpg'); */
    background-size: cover;
    background-position: bottom center;
  }
}
@media (max-width: 767px) {
  .page-content.banner.home {
    /* background-image: url('../../../assets/img/2022/patient/21-PHA-10478-global-mobile-patient-header.jpg'); */
    background-size: cover;
    background-position: bottom center;
  }
}
.page-content.banner.home.hcp {
  /* background-image: url('../../../assets/img/2022/hcp/21-PHA-10478-HCP-header.jpg'); */
}
.page-content.banner.home.hcp .btn {
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .page-content.banner.home.hcp {
    background-image: url('../../../assets/img/2022/hcp/21-PHA-10478-mobile-HCP-header.jpg');
    background-size: cover;
    background-position: bottom center;
  }
}
@media (max-width: 767px) {
  .page-content.banner.home .header-section__fullimage {
    display: block;
  }
}
.page-content.banner.home .header__smaller {
  font-size: 1.5em;
}
@media (max-width: 991px) {
  .page-content.banner.home .header__green {
    font-size: .9em;
    width: 70%;
    display: block;
    margin: 8px auto;
  }
}
@media (min-width: 768px) {
  .page-content.banner.home {
    height: 640px;
    background-size: auto 684px;
    background-position: center;
  }
  .page-content.banner.home .header-section__fullimage {
    height: 620px;
  }
  .page-content.banner.home .header-section__fullimage .header__blue {
    margin-bottom: 10px;
  }
}
.link__blue {
  font-size: 1.1em;
  color: #37607A;
  text-decoration: underline;
}
/* Headers */
.header-section .header__element {
  padding-bottom: 15px;
  margin-bottom: 17px;
}
.header-section .header__element sup {
  font-size: 18px;
  top: -0.75em;
}
.header-section .header__large {
  font-size: 30px;
  color: #6BA43A;
}
.header-section .header__large {
  @media (max-width: 991px) {
   img {
    float: left;
    width: 50px;
    height: 50px;
    margin-bottom: 30px;
   }

   span {
    display: block;
   }
  }
}
.header-section .header__large.header--gradient {
  /* Gradient Text */
  background-image: -webkit-gradient(linear, left top, right top, from(#74A240), to(#CFE3A1));
  background-image: -o-linear-gradient(left, #74A240, #CFE3A1);
  background-image: linear-gradient(90deg, #74A240, #CFE3A1);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.header-section .header__medium {
  color: #37607A;
  font-size: 18px;
}
.header-section .header--border {
  border-bottom: 1px solid #37607A;
  /* Ended up using other spacing.
		&.header--top-spacing-5 {
			margin-top: 50px;
		}

		&.header--top-spacing-2 {
			margin-top: 20px;
		}

		&.header--top-spacing-15 {
			margin-top: 15px;
		}

		&.header--bottom-spacing-5 {
			margin-bottom: 50px;
		}

		&.header--top-spacing-2 {
			margin-bottom: 20px;
		}

		&.header--top-spacing-15 {
			margin-bottom: 15px;
		}
		*/
}
.header__medium {
  color: #37607A;
  font-size: 20px;
}
.contents .page-content.home .container {
  padding: 0px;
}
.header-section__fullimage {
  width: 100%;
  height: 100%;
  padding-top: 20px;
}
@media (max-width: 1199px) {
  .header-section__fullimage {
    padding-top: 90px;
  }
}
@media (max-width: 767px) {
  img.fullwidth-imageadjustment,
  img.col-md-12,
  img.col-md-11,
  img.col-md-10,
  img.col-md-9,
  img.col-md-8,
  img.col-md-7,
  img.col-md-6,
  img.col-md-5,
  img.col-md-4,
  img.col-sm-12,
  img.col-sm-11,
  img.col-sm-10,
  img.col-sm-9,
  img.col-sm-8,
  img.col-sm-7,
  img.col-sm-6,
  img.col-sm-5,
  img.col-sm-4 {
    width: 60%;
    margin: 0 20%;
  }
}
@media (max-width: 991px) {
  img.fullwidth-imageadjustment,
  img.col-md-12,
  img.col-md-11,
  img.col-md-10,
  img.col-md-9,
  img.col-md-8,
  img.col-md-7,
  img.col-md-6,
  img.col-md-5,
  img.col-md-4,
  img.col-sm-12,
  img.col-sm-11,
  img.col-sm-10,
  img.col-sm-9,
  img.col-sm-8,
  img.col-sm-7,
  img.col-sm-6,
  img.col-sm-5,
  img.col-sm-4 {
    width: 90%;
    margin: 0 5%;
  }
}
img.mobilesize {
  width: 60%;
  margin: 0 20%;
}
.header__blue {
  color: #183649;
}
@media (max-width: 992px) {
  .header__blue {
    font-size: .7em;
  }
}
.header__green {
  color: #6BA43A;
  font-family: "BentonSans-Medium", Helvetica, Arial, sans-serif;
  font-size: 1.2em;
}
@media (max-width: 992px) {
  .header__green {
    font-size: .7em;
  }
}
.header__mobile--larger {
  font-size: 1.6em;
}
@media (min-width: 992px) {
}
.header__smaller {
  font-size: 1em;
}
@media (min-width: 768px) {
  .header__smaller {
    font-size: 1.65em;
  }
}
/* Text */
.text__drop-stat {
  font-size: 2.2em;
}
/* Subs */
.header__text__label {
  font-size: 8px;
  padding: 0px;
}
@media (min-width: 768px) {
  .header__text__label {
    font-size: 12px;
  }
}
.header__text__label ul {
  padding: 0px;
  margin-left: 12px;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .header__text__label ul {
    margin-bottom: 12px;
  }
}
.header__text__label ul li {
  color: #183649;
}
.header__text__label p {
  font-size: 6px;
  color: #183649;
  padding: 0px;
}
@media (min-width: 768px) {
  .header__text__label p {
    font-size: 9px;
  }
}
.header__lefthand__image {
  overflow: hidden;
}
.header__lefthand__text {

  @media (max-width: 767px) {
    margin-top: 20px;
  }

  @media (min-width: 768px) {
    margin-top: 0px;
    margin-left: 20px;
  }
}
.text__sub {
  font-size: 12px;
  color: #183649;
}
.text__simple-list {
  list-style: none;
  padding: 0px;
}
.text__simple-list li {
  margin-bottom: 30px;
  overflow: hidden;
}
.text__simple-list strong {
  color: #183649;
  padding-left: 0px;
}
/* Repeated Elements */
.information-box__rounded {
  /*
	&.box--dark-blue {
		background-color: @brand-drkblue;
		border: 1px solid @brand-drkblue;
	}
	&.box--blue {
		background-color: @brand-blue;
		border: 1px solid @brand-blue;
	}
	border-radius: 40px;

	.information-box__image {
		padding: 40px;
	}
	.information-box__text {
		background-color: #fff;
		border-radius: 0 0 38px 38px;
		text-align: center;
		color: #777;
		padding: 20px;
		font-weight: bold;
	}
	*/
}
.images__across__three strong {
  display: block;
}
.images__across__three img {
  max-width: 100px;
  margin-bottom: 20px;
}
.box__tops .box__top {
  background-color: #C4C4C4;
  padding: 20px;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 60px;
  border-radius: 10px 10px 0 0;
  border: 1px solid;
  border-width: 1px 1px 0px;
  color: #37607A;
}
.box__tops .box__top:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 20px solid #C4C4C4;
  position: absolute;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.box__tops .box__bottom {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.box__tops .box__bottom ul {
  padding: 25px 0;
  background-color: #fff;
  border: 1px solid;
  border-width: 0px 1px 1px 1px;
  border-radius: 0 0 50px 50px;
}
@media (min-width: 992px) {
  .box__tops .box__bottom ul {
    height: 320px;
  }
}
.box__tops .box__bottom ul li {
  color: #37607A;
  margin: 0 20px 15px 40px;
}
ul.arrows li {
  margin-bottom: 20px;
}
.lower-right {
  position: absolute;
  bottom: 0px;
  text-align: left;
  width: 35%;
  padding: 0px;
  display: block;
  right: 15%;
}
.lower-right ul {
  padding: 0px;
}

.container .marketing .marketing-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: left;
  padding: 20px 15px;
}
@media (min-width: 992px) {
  .container .marketing .marketing-item {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .container .marketing .marketing-item:first-child {
    padding-left: 0px;
  }
}
.container .marketing .marketing-item:last-child {
  padding-right: 0px;
}
@media (min-width: 992px) {
  .container .marketing .marketing-item.col-1-up .marketing-btn-text {
    min-height: 90px;
  }
}
@media (min-width: 992px) {
  .container .marketing .marketing-item .marketing-btn-text {
    min-height: 150px;
  }
}
.container .marketing .marketing-item .marketing-btn-text p {
  text-align: left;
}
.page-content.dark-hcp {
  background-size: 106%;
}
@media (min-width: 992px) {
  .page-content.dark-hcp .container {
    min-height: 615px;
  }
}
.icon-list .icon-list-item img {
  width: initial;
}
.page-content .subhead-strong.gradient-bg {
  background: none;
}
.page-content ul.arrows ul.nonarrows li:before {
  content: '\00B7';
  font-size: 34px;
  color: #183649;
  line-height: 11px;
}
.page-content ul.arrows ul.nonarrows .hyphened {
  padding-left: 30px;
  position: relative;
}
.page-content ul.arrows ul.nonarrows .hyphened:before {
  content: '';
  position: absolute;
  left: 10px;
  border-bottom: 1.5px solid;
  width: 12px;
  top: 10px;
}
.page-content .blue-caption {
  text-align: center;
  background-color: #183649;
  color: #fff;
  padding: 5px;
  clear: both;
  padding-bottom: 0px;
}
.highlighted__blue-background {
  background-color: #183649;
  padding: 5px 5px 3px;
}
.highlighted__blue-background strong,
.highlighted__blue-background p {
  color: #fff;
}
.content__404 .container {
  margin-top: 200px;
  margin-bottom: 100px;
}
.page-content .green__box {
  background-color: #fff;
  border: 2px solid #6BA43A;
  color: #6BA43A;
  padding: 15px;
  border-radius: 10px;
  margin: 15px 0 15px 15px;
  font-weight: bold;
  float: right;
}
.page-content .green__box p {
  padding: 0;
}
.dosage__table {
  color: #fff;
  width: 100%;
  overflow: auto;
  display: block;
}
.dosage__table tbody {
  display: block;
}
.dosage__table tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dosage__table tr th {
  border-radius: 10px;
  margin-bottom: 15px;
  margin-right: 15px;
  padding: 15px;
}
.dosage__table tr td {
  border-radius: 10px;
  margin-bottom: 15px;
  margin-right: 15px;
  -ms-flex-item-align: center;
      align-self: center;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 60px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dosage__table .header__row {
  line-height: 1em;
}
.dosage__table .header__row span {
  background-color: #fff;
  color: #183649;
  border-radius: 5px;
  display: block;
  height: 100%;
  padding: 5px 10px;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dosage__table .header__row small {
  width: 100%;
}
.dosage__table .header__row img {
  width: 20px;
  margin-left: auto;
}
.dosage__table .header__row.first__column,
.dosage__table .header__row.second__column {
  font-size: 15px;
}
.dosage__table .header__row .third__column {
  font-size: 1.25em;
}
.dosage__table .first__column {
  background-color: #263c46;
  -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
}
.dosage__table .first__column span {
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.dosage__table .second__column {
  background: #18374a;
  background: -webkit-gradient(linear, left top, right top, from(#18374a), to(#2b5067));
  background: -o-linear-gradient(left, #18374a 0%, #2b5067 100%);
  background: linear-gradient(90deg, #18374a 0%, #2b5067 100%);
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
}
.dosage__table .second__row .third__column {
  padding: 5px 15px;
}
.dosage__table .third__column {
  background: #2b5067;
  background: -webkit-gradient(linear, left top, right top, from(#2b5067), to(#74a342));
  background: -o-linear-gradient(left, #2b5067 0%, #74a342 100%);
  background: linear-gradient(90deg, #2b5067 0%, #74a342 100%);
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
  margin-right: 0px;
}
.image__border--right {
  padding-right: 0px;
}
.image__border--right img {
  padding-right: 30px;
}
@media (min-width: 992px) {
  .image__border--right img {
    border-right: 1px solid;
  }
}
.unique__vein-care-icons {
  border-left: 1px solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.unique__vein-care-icons .icon-container {
  padding: 40px 0px 70px 0;
  text-align: center;
}
.unique__vein-care-icons .icon-container img {
  height: 130px;
}
.unique__vein-care-icons .icon-container.clear {
  padding: 40px 0px 30px 0;
}
.block__statistic {
  position: relative;
}
.block__statistic .element__image {
  width: 100%;
}
@media (min-width: 992px) {
  .block__statistic.nintyseven img {
    min-height: 269px;
  }
}
.block__statistic.nintythree .element__caption {
  bottom: 3px;
  width: 76%;
}
@media (min-width: 992px) {
  .block__statistic.nintythree .element__caption {
    bottom: 13px;
    width: 80%;
  }
}
@media (min-width: 992px) {
  .block__statistic.nintythree img {
    min-height: 250px;
  }
}
.block__statistic.eightyfive .element__caption {
  bottom: 33px;
}
.block__statistic.threebox .element__caption {
  background: none;
  color: #57585A;
  top: 160px;
  font-size: 1em;
  bottom: initial;
}
@media (min-width: 480px) {
  .block__statistic.threebox .element__caption {
    top: 260px;
    font-size: 1.2em;
  }
}
@media (min-width: 768px) {
  .block__statistic.threebox .element__caption {
    top: 90px;
    font-size: .65em;
  }
}
@media (min-width: 992px) {
  .block__statistic.threebox .element__caption {
    top: 120px;
    font-size: 1em;
  }
}
.block__statistic .element__caption {
  position: absolute;
  width: 84%;
  left: 8%;
  text-align: center;
  bottom: 10%;
  background: #fff;
  font-size: 3vw;
  border-radius: 15px;
  color: #183649;
}
@media (min-width: 768px) {
  .block__statistic .element__caption {
    font-size: .65em;
    bottom: 5%;
  }
}
@media (min-width: 992px) {
  .block__statistic .element__caption {
    font-size: 1em;
    bottom: 23px;
  }
}
.social-icons small {
  color: #5c6156;
}
.social-icons .list-inline {
  margin-right: 225px;
}
.social-icons .list-inline img {
  width: 20px;
  height: auto;
}
.social-icons .list-inline img.youtube {
  width: 30px;
}
.icon__spacing {
  width: 20%;
  text-align: center;

  @media (min-width: 768px) {
    width: 40%;
  }
}
.icon__spacing img {
  max-width: 60px;
  max-height: 80px;
  padding-right: 10px;
}

.video-player iframe {
  width: 100%;
}
.howsophiemanages {
  min-height: 220px;
}
.vjs_video_409 {
  min-height: 200px;
}
@media (min-width: 768px) {
  .vjs_video_409 {
    min-height: 370px;
  }
}
@media (min-width: 992px) {
  .vjs_video_409 {
    min-height: 230px;
  }
}
.vjs_video_1095 {
  min-height: 170px;
}
@media (min-width: 768px) {
  .vjs_video_1095 {
    min-height: 400px;
  }
}
@media (min-width: 992px) {
  .vjs_video_1095 {
    min-height: 400px;
  }
}
.btn {
  -webkit-filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.253278));
          filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.253278));
}
.dropdown-menu {
  // background: -webkit-gradient(linear, left top, left bottom, from(#487521), color-stop(20.05%, #6BA43A));
  // background: -o-linear-gradient(top, #487521 0%, #6BA43A 20.05%);
  // background: linear-gradient(180deg, #487521 0%, #6BA43A 20.05%);
  background-color: #70A04B;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  border: none;
  right: initial;
  left: 0px;
  padding: 0;
}
.page-links .dropdown {
  top: -2px;
}
body {
  padding-right: 0 !important;
}
.receive_commications_option,
.receive_info_option {
  color: #6BA43A;
}

//
//
//

body {
  .display-flex(); 
  min-height: 100vh;
  flex-direction: column;
}

.contents {
  section.banner {
    @media (max-width: @screen-sm-max) {
      margin-top: 70px;
    }
  }
}

small {
  font-size: 12px;
  line-height: normal;
}

strong,
.bold {
  font-family: @headings-font-family;
  font-weight: @headings-font-weight;
}

.green {
  color: @link-color;
}

.btn {
  padding: 8px 20px 6px 20px;
  font-size: 15px;
}

.btn-lg {
  padding: 15px 15px 14px 15px;
  font-size: 20px;
}

.p-0 {
  padding: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-2 {
  padding-top: 2px !important;
}

.p-t-5 {
  padding-top: 5 !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-l-10 {
  padding-right: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-0-md {
  @media (min-width: @screen-md-min) {
    margin-bottom: 0 !important;
  }
}

.m-h-2per {
  margin: 0 2% !important;
}

.m-h-5per {
  margin: 0 5% !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;

  @media (max-width: @screen-md-min) {
    margin-bottom: 30px !important;
  }
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.m-b-80 {
  margin-bottom: 100px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.h-100 {
  height: 100px;
}

.push-top {
  margin-top: 50px;
  margin-bottom: 70px;
}

@media (max-width: 1199px) {
  .push-top {
    margin-top: 70px;
  }
}

.solutions-img {
  margin: 70px auto 30px auto;
}

br {
  &.lg {
    @media (max-width: @screen-md-max) {
      display: none;
    }
  }
}

.no-wrap {
  white-space: nowrap;
}

sub {
  bottom: 0;
}

.inline-vcenter {
  display: flex;

  img {
    margin-right: 15px;
    align-self: flex-start;
    flex-shrink: 0;
  }
}

.page-content {
  padding: @padding-section-more;
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #edf9ff 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #edf9ff 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #edf9ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#edf9ff', GradientType=0);

  &.page-content__noline {
    border-bottom: none;
  }

  h2 {
    margin-bottom: 25px;

    &:not(:first-child) {
      margin: 10px 0 25px 0;
    }

    sup {
      font-size: 50%;
      top: -0.8em;
      margin-left: 1px;
    }
  }

  p {
    padding-bottom: 15px;
    line-height: 1.5;

    &.citing {
      line-height: 1 !important;
    }

    .larger {
      font-size: 20px;
    }
  }

  .subhead-strong {
    border-bottom: 1px solid @brand-primary;
    margin-bottom: 18px;

    p {
      font-weight: @headings-font-weight;
      font-family: @font-family-condensed-bold;
      font-size: 20px;
      padding-bottom: 10px;

      &.hae-smaller {
        font-size: 19px;
      }
    }

    &.no-line {
      border-bottom: none;
      margin-bottom: 0;
      text-align: center;
    }

    &.double {
      border-top: 1px solid @brand-primary;

      p {
        font-weight: @headings-font-weight;
        font-family: @headings-font-family;
        text-align: center;
        padding: 10px 0;
        margin: 0 9%;
        line-height: 1.25;
      }
    }

    &.gradient-bg {
      background: -moz-linear-gradient(
        left,
        rgba(228, 233, 239, 0) 0%,
        rgba(228, 233, 239, 0.8) 7%,
        rgba(228, 233, 239, 0.8) 10%,
        rgba(228, 233, 239, 0.8) 13%,
        rgba(228, 233, 239, 1) 86%,
        rgba(228, 233, 239, 0.89) 90%,
        rgba(228, 233, 239, 0.8) 93%,
        rgba(228, 233, 239, 0) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(228, 233, 239, 0) 0%,
        rgba(228, 233, 239, 0.8) 7%,
        rgba(228, 233, 239, 0.8) 10%,
        rgba(228, 233, 239, 0.8) 13%,
        rgba(228, 233, 239, 1) 86%,
        rgba(228, 233, 239, 0.89) 90%,
        rgba(228, 233, 239, 0.8) 93%,
        rgba(228, 233, 239, 0) 100%
      );
      background: linear-gradient(
        to right,
        rgba(228, 233, 239, 0) 0%,
        rgba(228, 233, 239, 0.8) 7%,
        rgba(228, 233, 239, 0.8) 10%,
        rgba(228, 233, 239, 0.8) 13%,
        rgba(228, 233, 239, 1) 86%,
        rgba(228, 233, 239, 0.89) 90%,
        rgba(228, 233, 239, 0.8) 93%,
        rgba(228, 233, 239, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00e4e9ef', endColorstr='#00e4e9ef', GradientType=1);
    }
  }

  h5 {
    padding-top: 20px;
    margin-bottom: 10px;
  }

  &.banner {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    margin-top: @navbar-large-height;

    .btn {
      padding: 8px 23px 6px 23px;
      letter-spacing: 1px;
    }

    @media (max-width: @screen-md) {
      .btn {
        font-size: 10px;
      }
    }

    h5 {
      padding: 0;
      font-size: 145%;
      margin-bottom: 10px;

      &:not(:first-child) {
        font-size: 108%;
        margin-top: 8px;
      }
    }

    .triangle-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 900;
      position: relative;

      @media (max-width: 768px) {
        display: none;
      }

      &:first-child {
        .triangle {
          &:first-child {
            width: 0;
            height: 0;
            border-bottom: 120px solid transparent;
            border-left: 60px solid @brand-primary;
            display: block;
          }

          &:last-child {
            width: 0;
            height: 0;
            border-top: 120px solid transparent;
            border-left: 60px solid @link-color;
            display: block;
          }
        }
      }

      &:last-child {
        .triangle {
          &:first-child {
            width: 0;
            height: 0;
            border-bottom: 120px solid transparent;
            border-right: 60px solid lighten(@link-color, 20%);
            display: block;
          }

          &:last-child {
            width: 0;
            height: 0;
            border-top: 120px solid transparent;
            border-right: 60px solid lighten(@link-color, 20%);
            display: block;
          }
        }
      }
    }

    .container {
      align-items: center;
      margin: 0;
      padding: 10px 15px 30px 15px;

      .banner-content {
        width: 100%;
      }

      .flex-half-width {
        width: 50%;

        @media (max-width: @screen-sm-max) {
          width: 100%;
        }
      }
    }
  }

  &.resources {
    .btn-default {
      min-width: 190px;
    }
  }

  &.references {
    ol {
      margin: 0 0 1.5em;
      padding: 0;
      counter-reset: item;

      li {
        margin: 0;
        padding: 0 0 10px 2em;
        text-indent: -1.5em;
        list-style-type: none;
        counter-increment: item;

        &:before {
          font-weight: @headings-font-weight;
          font-family: @headings-font-family;
          display: inline-block;
          width: 1.5em;
          padding-right: 0.5em;
          text-align: right;
          content: counter(item) ".";
        }
      }
    }
  }

  &.hcp {
    .container {
      @media (min-width: @screen-md-min) {
        height: 620px;
      }

      .flex-half-width {
        &:last-child {
          @media (min-width: @screen-md-min) {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
          }
        }
      }
    }
  }

  &.home {
    .container {
      display: flex;
      flex-direction: row;

      @media (max-width: @screen-sm-max) {
        flex-direction: column;
      }

      .flex-half-width {
        @media (max-width: @screen-sm-max) {
          text-align: center;
        }
      }
    }

    .banner-image {
      @media (min-width: @screen-md-min) {
        width: 700px;
        min-height: 100%;
        z-index: 0;
        background-position: 50% 50%;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background: url("@{images}/banner-bg.png") no-repeat;
        height: 490px;
        margin-top: 30px;
      }

      @media (max-width: @screen-sm-max) {
        padding: 20px 0 30px 0;
      }

      &.hcp {
        @media (min-width: @screen-md-min) {
          width: 924px;
          height: 450px;
          background: url("@{images}/banner-bg-hcp.png") no-repeat;
        }

        @media (max-width: @screen-sm-max) {
          text-align: right;
        }

        img {
          @media (max-width: @screen-sm-max) {
            display: relative;
          }
        }
      }

      img {
        @media (min-width: @screen-md-min) {
          display: none;
        }
      }
    }
  }

  .hae {
    padding: 0;
    margin-bottom: -15px;

    @media (max-width: @screen-sm-max) {
      margin-top: 20px;
    }
  }
}

ul.arrows {
  padding-left: 20px;
  padding-top: 10px;
}
@media (max-width: 1200px) {
  ul.arrows {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
  }
}
ul.arrows.col-2-up {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
}
ul.arrows li {
  display: block;
  -webkit-column-break-inside: avoid;
     -moz-column-break-inside: avoid;
          break-inside: avoid;
}
ul.arrows li:before {
  content: "\e072";
  font-family: "Glyphicons Halflings";
  font-size: 14px;
  float: left;
  margin-left: -20px;
  color: #74a433;
}
ul.linelist {
  padding-left: 80px;
  padding-top: 10px;
}
@media (max-width: 1200px) {
  ul.linelist {
	padding-left: 20px;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
  }
}
ul.linelist.col-3-up {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
}
ul.linelist.col-2-up {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
}
ul.linelist li {
  display: block;
  -webkit-column-break-inside: avoid;
     -moz-column-break-inside: avoid;
          break-inside: avoid;
}
ul.linelist li:before {
  content: "-";
  font-family: "Glyphicons Halflings";
  font-size: 14px;
  float: left;
  margin-left: -20px;
  color: #74a433;
}
.extendedBackground {
  max-width: 1440px;
  margin: 0 auto;
}

.error {
  font-size: 12px;
  color: @brand-danger;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

h5 {
  color: @brand-primary;
  font-size: 110%;
  margin-bottom: 20px;
}

.condensed {
  font-family: @font-family-condensed !important;
}

.condensed-med {
  font-family: @font-family-condensed-medium !important;
}

.condensed-bold {
  font-weight: @headings-font-weight !important;
  font-family: @font-family-condensed-bold !important;
  letter-spacing: 0.5px;
}

.small-one-line {
  font-size: 14.5px;
}

p.large-text {
  font-size: 20px !important;
}

p.lead {
  font-size: 24px !important;
}

sup {
  margin-left: 0;

  a {
    color: inherit;
    margin-right: -3px;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }

    &.visible-xs {
      margin-right: -3px;

      @media (max-width: @screen-xs-max) {
        display: inline !important;
      }
    }
  }
}

// RESOURCES
.two-columns {
  display: flex;
  justify-content: space-between;

  @media (max-width: @screen-sm-max) {
    flex-direction: column;
  }

  .icon-list-item {
    display: flex;

    @media (min-width: @screen-md-min) {
      padding-right: 20px;

      &:first-child {
        width: 50%;
        padding-right: 20px;
      }

      &:last-child {
        width: 45%;
      }
    }

    @media (max-width: @screen-sm-max) {
      width: 100%;

      &:not(:first-child) {
        margin-top: 50px;
      }

      .image-container {
        flex-shrink: 0;
        min-width: 243px;

        img {
          .center-block();
        }
      }
    }

    @media (max-width: @screen-xs-max) {
      flex-direction: column;

      img {
        .center-block();
        margin-bottom: 20px;
      }
    }

    .text {
      margin-left: 20px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: @screen-xs-max) {
        margin-left: 0;
        align-items: center;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      p {
        font-size: 95%;
        padding-bottom: 5px;

        @media (max-width: @screen-xs-max) {
          text-align: center;
        }
      }

      .btn {
        padding: 8px 13px 6px 18px;

        //font-size: 13px;
        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }

    img {
      flex-shrink: 0;
      height: 260px;
      width: auto;
      box-shadow: 0 0 4px 0px #00000047;

      @media (max-width: @screen-sm-max) {
        height: 215px;
        width: auto;
      }
    }
  }
}

.three-columns {
  display: flex;
  justify-content: space-between;

  @media (max-width: @screen-sm-max) {
    flex-direction: column;
  }

  .icon-list-item {
    display: flex;

    @media (min-width: @screen-md-min) {
      padding-right: 10px;
      width: 33.3%;

      //&:first-child {
      //	width: 50%;
      //	padding-right: 20px;
      //}
      &:last-child {
        padding-right: 0;
      }
    }

    @media (max-width: @screen-sm-max) {
      width: 100%;

      &:not(:first-child) {
        margin-top: 50px;
      }

      .image-container {
        flex-shrink: 0;
        min-width: 160px;

        img {
          .center-block();
        }
      }
    }

    @media (max-width: @screen-xs-max) {
      flex-direction: column;

      img {
        .center-block();
        margin-bottom: 20px;
      }
    }

    .text {
      margin-left: 10px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: @screen-xs-max) {
        margin-left: 0;
        align-items: center;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      p {
        font-size: 95%;

        @media (min-width: @screen-md-min) {
          font-size: 85%;
        }

        padding-bottom: 5px;

        @media (max-width: @screen-xs-max) {
          text-align: center;
        }
      }

      .btn {
        padding: 8px 18px 6px 18px;
        font-size: 13px;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }

    img {
      flex-shrink: 0;
      height: 160px;
      width: auto;
      box-shadow: 0 0 4px 0px #00000047;

      @media (max-width: @screen-sm-max) {
        height: 215px;
        width: auto;
      }
    }
  }
}

.btn-border-container {
  display: flex;
  justify-content: center;

  .btn-border {
    border-top: solid @brand-primary 1px;
    padding-top: 15px;
  }
}

@font-face {
  font-family: "BentonSans-Regular";
  src: url("@{fonts}/BentonSans-Regular.eot");
  src: url("@{fonts}/BentonSans-Regular.eot") format("embedded-opentype"),
    url("@{fonts}/BentonSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BentonSans-Medium";
  src: url("@{fonts}/BentonSans-Medium.eot");
  src: url("@{fonts}/BentonSans-Medium.eot") format("embedded-opentype"),
    url("@{fonts}/BentonSans-Medium.ttf") format("truetype");
  font-weight: @headings-font-weight;
  font-style: normal;
}

@font-face {
  font-family: "BentonSansCond";
  src: url("BentonSansCond-Regular.eot");
  src: url("@{fonts}/BentonSansCond-Regular.eot") format("embedded-opentype"),
    url("@{fonts}/BentonSansCond-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BentonSansCond-Bold";
  src: url("@{fonts}/BentonSansCond-Bold.eot");
  src: url("@{fonts}/BentonSansCond-Bold.eot") format("embedded-opentype"),
    url("@{fonts}/BentonSansCond-Bold.ttf") format("truetype");
  font-weight: @headings-font-weight;
  font-style: normal;
}

@font-face {
  font-family: "BentonSansCond";
  src: url("@{fonts}/BentonSansCond-Medium.eot");
  src: url("@{fonts}/BentonSansCond-Medium.eot") format("embedded-opentype"),
    url("@{fonts}/BentonSansCond-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BentonSansCond-Book";
  src: url("@{fonts}/BentonSansCond-Book.eot");
  src: url("@{fonts}/BentonSansCond-Book.eot") format("embedded-opentype"),
    url("@{fonts}/BentonSansCond-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.image-grid {
  margin-top: 25px;
  padding-left: 1px;
  float: left;
  width: 25%;

  @media (max-width: @screen-sm-max) {
    width: 49%;
  }

  img {
    height: 127px;
    width: 191px;
    margin: 0 auto;
  }

  p {
    text-align: center;
    padding-top: 5px;

    @media (max-width: @screen-xs-max) {
      font-size: 13px;
    }
  }
}

.symptom-free-icon {
  @media (max-width: @screen-sm-max) {
    padding: 0 20px;
  }

  @media (max-width: @screen-xs-max) {
    padding: 30px 0 10px 0;
  }
}

.response {
  font-weight: @headings-font-weight;
  font-family: @headings-font-family;
  text-align: center;
  margin-top: 30px;
}

.video-container {
  text-align: center;

  @media (max-width: @screen-sm-max) {
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .video-player {
    padding: 10px 0;

    img {
      cursor: pointer;
    }
  }

  p {
    font-size: 90% !important;
    padding-bottom: 2px;
    line-height: 1.3;
    letter-spacing: 0.5px;
  }
}

.dosing-calculator {
  border: 1px lighten(@brand-primary, 30%) solid;

  p {
    padding: 0;
  }

  .header {
    background-color: @brand-primary;
    color: #fff;
    font-weight: @headings-font-weight;
    font-family: @headings-font-family;
    text-align: center;
    padding: 8px;
  }

  .body {
    padding: 20px;

    form {
      display: flex;
      margin-top: 8px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      input {
        width: auto;
      }

      button {
        // margin-left: 15px;
      }
    }
  }

  .footer {
    border-top: 1px lighten(@brand-primary, 30%) solid;
    padding-top: 20px;
    margin: 0 20px 20px 20px;
    display: flex;
    justify-content: space-between;

    strong {
      color: #74a433;

      span.blue {
        width: 8px;
        height: 5px;
        display: inline-block;
      }
    }
  }

  .btn {
    min-width: 100px;
  }
}

.citing {
  padding-bottom: 2px !important;

  small {
    sup {
      margin-right: 0;

      &:first-child {
        margin-left: -5px;
      }
    }
  }

  &.less-margin {
    small {
      sup {
        &:first-child {
          margin-left: -3px;
        }
      }
    }
  }
}

.calc-index {
  padding: 15px 25px;
  font-size: 90%;
}

.text-side-icon {
  display: flex;
  padding: 10px 0;

  @media (max-width: @screen-sm-max) {
    flex-direction: column;
  }

  .text-container {
    flex: 6;

    p:last-child {
      padding: 0;
    }
  }

  .icon-container {
    flex: 1;
    padding: 20px 0 20px 40px;
    display: flex;
    align-items: center;
    border-left: solid 1px @brand-primary;
    margin-left: 20px;
    align-items: flex-start;
    
    img {
      align-self: center;
    }

    @media (max-width: @screen-sm-max) {
      display: none;
    }

    &.slim {
      padding: 0 0 0 40px;
      align-items: center;
      justify-content: center;
    }

    &.no-line {
      border-left: none;
    }
  }

  ul {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;

    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.marketing {
  display: flex;
  justify-content: center;

  @media (max-width: @screen-sm-max) {
    flex-direction: column;
  }

  .marketing-item {
    text-align: center;
    min-height: 220px;

    @media (min-width: @screen-md-min) {
      padding: 0;
    }

    @media (max-width: @screen-sm-max) {
      padding: 30px;
    }

    @media (max-width: 436px) {
      min-height: 280px;
    }

    &:not(:first-child) {
      @media (max-width: @screen-sm-max) {
        border-top: 1px solid @brand-primary;
      }

      @media (min-width: @screen-md-min) {
        border-left: 1px solid @brand-primary;
      }
    }

    .marketing-icon {
      flex-shrink: 0;
    }

    .marketing-btn-text {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      p {
        width: 100%;
      }
    }

    strong {
      font-weight: @headings-font-weight;
      font-family: @headings-font-family;
      font-size: 20px;
    }

    &.col-3-up {
      flex: 1;
      display: flex;
      flex-direction: column;

      @media (max-width: @screen-sm-max) {
        width: 100%;
      }
    }

    &.col-2-up {
      flex: 1;
      display: flex;
      flex-direction: column;

      @media (min-width: @screen-md-min) {
        padding: 0 80px;
      }

      @media (max-width: @screen-sm-max) {
        width: 100%;
      }

      .marketing-content {
        margin-top: 5px;
      }
    }

    &.col-1-up {
      width: 33%;
      display: flex;
      flex-direction: column;

      @media (max-width: @screen-sm-max) {
        width: 100%;
      }
    }
  }

  .marketing-content {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    margin-top: 7px;
    flex: 1;

    p {
      font-size: 14px;
      -ms-flex: 1 1 auto;
    }

    .btn {
      margin-top: 5px;
    }

    img {
      margin-right: 15px;
    }
  }
}

.table {
  text-align: center;
  border: solid 1px @brand-primary;
  font-size: 90%;
  margin-bottom: 12px;

  strong {
    font-weight: @headings-font-weight !important;
    font-family: @font-family-condensed-bold !important;
  }

  .table-header {
    color: #fff;
    font-family: @font-family-condensed-medium;
    background-color: @brand-primary;
    padding: 10px 40px;
    font-size: 117%;

    p {
      padding: 0;
    }
  }

  .table-row {
    background-color: #f1f3f7;
    display: flex;
    align-items: center;

    &:nth-child(even) {
      background-color: #e4e9ef;
    }
  }

  .table-cell {
    display: inline-block;
    text-align: center;
    width: 49%;
    padding: 10px;
    font-family: @font-family-condensed;

    p {
      padding: 0;
    }
  }

  &.cell-3-up {
    .table-row {
      .table-cell {
        width: 25%;

        // p {
        // 	font-size: 76%;
        // }
        &:last-child {
          width: 50%;
        }
      }
    }
  }

  p {
    padding: 0;
  }
}

.sheilds {
  display: flex;
  justify-content: space-around;
  margin: 25px 30px 30px 30px;

  @media (max-width: @screen-sm-max) {
    flex-direction: column;
  }

  .shield {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    padding: 0 45px;
    text-align: center;

    @media (max-width: @screen-sm-max) {
      margin-top: 30px;
    }

    @media (max-width: @screen-sm-max) {
      padding: 0;
    }

    img {
      margin-bottom: 20px;
    }

    h5 {
      padding: 0;
    }

    p {
      @media (max-width: @screen-sm-max) {
        width: 100%;
      }
    }
  }
}



form {
  margin-top: 20px;

  .checkbox {
    label {
      margin-left: 0;
    }
  }

  .form-control {
    box-shadow: none;
    border-radius: 5px !important;
  }

  .radio {
    margin-right: 5px;
    vertical-align: top;

    label {
      width: 100%;
      margin-left: 0;
      font-size: 13px;

      @media (max-width: @screen-sm-max) {
        font-size: 16px;
      }
    }

    p {
      display: inline-block;
      width: 90%;
      padding-bottom: 0;
    }

    input {
      vertical-align: top;
      margin-top: 2px;
    }
  }

  .radio-circle {
    margin-left: 20px;

    @media (max-width: 991px) {
      input {
        vertical-align: top;
        margin-top: 5px !important;
      }
    }
  }

  input[type="radio"] {
    margin: 1px 0 0;
  }

  .row {
    @media (min-width: @screen-md-min) {
      margin-bottom: 15px;
    }
  }

  .radio-row {
    @media (max-width: @screen-sm-max) {
      padding: 30px 0;
    }
  }

  .btn {
    min-width: 180px;
  }

  label {
    margin-bottom: 10px;
  }

  .form-block {
    border-bottom: solid 2px #4e758b;
  }

  .checkbox-confirm {
    margin-left: 20px;
  }
}

.sup-reduce {
  display: inline-block;
  font-size: 60%;
  vertical-align: 2px;
}

.connection {
  .connection-wrapp {
    width: 954px;
    margin: 0 auto !important;

    @media (max-width: 1100px) {
      width: 854px;
    }

    @media (max-width: 992px) {
      width: 750px;
    }

    @media (max-width: 870px) {
      width: 650px;
    }

    @media (max-width: 767px) {
      width: ~"calc(100% - 40px)";
      margin-left: 20px;
      margin-right: 20px;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      width: 618px;
    }
  }

  .connection-poster {
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 70px;
    width: 954px;
    height: auto;

    @media (max-width: 1100px) {
      width: 854px;
    }

    @media (max-width: 992px) {
      width: 750px;
    }

    @media (max-width: 870px) {
      width: 650px;
    }

    @media (max-width: 767px) {
      width: ~"calc(100% - 40px)";
      margin-left: 20px;
      margin-top: 30px;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      width: 618px;
    }
  }

  .form-block {
    border-bottom: solid 2px #4e758b;
    padding-top: 50px;
    padding-bottom: 50px;

    &:last-child {
      border-bottom: none;
    }

    p {
      display: inline-block;
      width: 80%;
      padding-bottom: 0;
    }

    input {
      vertical-align: top;
    }
  }

  p.footnote {
    font-size: 12px;
    padding-left: 6px;
    font-style: italic;
    margin-top: 5px;
    padding-bottom: 0;

    span {
      margin-left: -6px;
    }
  }

  p.footnote-below {
    padding-left: 0;
    font-style: normal;
  }

  .border-right {
    padding-right: 50px;

    .form-group {
      margin-bottom: 30px;
    }

    @media (max-width: 992px) {
      padding-right: 10px;
    }
  }

  .border-left {
    border-left: solid 2px #4e758b;
    padding-left: 50px;

    .form-group {
      margin-bottom: 30px;
    }

    @media (max-width: 992px) {
      border: none;
      padding-left: 10px;
    }

    label {
      display: block;
    }

    .label_inline {
      display: inline-block;
      margin-top: 18px;
    }
  }

  .select-group {
    select {
      margin-bottom: 15px;
    }
  }

  .connection_checkbox {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .input_line {
    display: inline-block;
    width: ~"calc(100% - 85px)";
    margin-left: 20px;
    margin-top: 15px;

    @media (max-width: 992px) {
      width: ~"calc(100% - 75px)";
      margin-left: 10px;
    }
  }

  .input_block {
    width: 100%;
    display: inline-block;
    min-height: 100px;
  }

  .accept-checkbox {
    width: 80%;
    margin-left: 70px;
    margin-right: 0;
    margin-bottom: 40px;
    position: relative;

    input {
      margin-left: -20px;
      position: absolute;
      top: 5px;
      left: 0;
    }

    h5 {
      margin: 0;
      display: inline-block;
      padding-top: 0;
    }

    @media (max-width: 992px) {
      margin-left: 20px;
      width: ~"calc(100% - 20px)";
    }
  }

  .btn {
    margin-left: 50px;
  }

  .con-list {
    padding-top: 0 !important;
    padding-bottom: 15px;
  }

  .connection_contact {
    margin-bottom: 50px;
  }

  .connection_contact-text {
    padding-bottom: 0;
    text-align: center;
  }

  .connection_contact-phone {
    font-size: 30px;
    margin-top: 0;
    text-align: center;
    display: block;
    text-decoration: none;

    &:hover {
      color: #74a433;
    }
  }

  .connection_logo {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  .connection_logo-img {
    width: 173px;
    height: auto;
    margin: 0 auto;
    display: block;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }
}

.cont {
  padding-top: 20px;
}

.connection-register-button {
  width: 280px;
  max-width: 280px;
  display: block;
  padding-top: 15px !important;
  padding-bottom: 14px !important;
  margin: 30px auto !important;
}

.symptom-icons {
  border-radius: 4px;
  padding: 0 0px 30px 0px;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .icon {
    padding-top: 30px;
    display: flex;
    align-items: center;
    width: 24%;

    @media (min-width: @screen-md-min) {
      padding-right: 20px;
    }

    @media (max-width: @screen-sm-max) {
      width: 50%;
    }

    @media (max-width: @screen-xs-max) {
      width: 100%;
    }

    img {
      margin-right: 15px;
    }

    p {
      font-weight: @headings-font-weight;
      font-family: @headings-font-family;
      padding: 0;
    }
  }
}

.icon-list {
  display: flex;
  flex-direction: column;

  .icon-list-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    @media (min-width: @screen-md-min) {
      &:last-child {
        p {
          margin-left: 65px;
          line-height: normal;
        }
      }
    }

    img {
      margin-right: 15px;
      align-self: flex-start;
      flex-shrink: 0;
    }

    p {
      padding: 0;

      strong {
        text-transform: uppercase;
      }
    }
  }

  &.h-50 {
    .icon-list-item {
      margin-bottom: 25px;

      img {
        height: 50px;
        width: auto;
        flex-shrink: 0;
      }

      .citing {
        small {
          font-size: 11px !important;
        }
      }
    }
  }

  &.col-2-up {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: @screen-sm-max) {
      flex-direction: column;
    }

    .icon-list-item {
      width: 45%;

      @media (max-width: @screen-sm-max) {
        width: 100%;
      }

      &:last-child {
        p {
          margin-left: 0;
        }
      }
    }
  }
}

/*
#expandable-footer.fullscreen {
  z-index: 9999;
  height: auto;
  max-height: 80vh;
  bottom: 0;
}
*/

#expandable-footer #expandable-footer-inner {
  overflow-y: scroll;
}

#expandable-footer {
  .fixed {
    bottom: 0;
    margin-bottom: 0;
    z-index: 1030;
    max-height: initial;
    width: 100%;
    left: 0;
    position: fixed;
    overflow-y: scroll;
    height: 100px;

    #btn-expand {
      display: inline-block;
    }

    #btn-collapse {
      display: none;
    }
  }

  .contents {
    box-shadow: 0 -2px 60px -21px rgba(0, 0, 0, 0.7);
    border-top: 2px #37607A solid;
    background-color: #fff;
    padding: 0;
  }

  #btn-expand {
    display: none;
    color: #6BA43A;
  }

  #btn-collapse {
    display: inline-block;
    color: #6BA43A;
  }

  p,
  li {
    font-size: 16px;
    font-family: @font-family-condensed;
  }

  p {
    padding-bottom: 10px;
  }

  strong {
    font-family: @font-family-condensed-bold;
  }

  h5 {
    margin-bottom: 3px;
    font-family: @font-family-condensed-bold;
    font-weight: 700;
  }

  ul {
    padding-left: 18px;
  }

  .contents {
    box-shadow: 0 -2px 60px -21px rgba(0, 0, 0, 0.7);
    border-top: 2px @brand-primary solid;
    background-color: #fff;
    padding: 0 15px;

    @media (max-width: @screen-xs-max) {
      padding: 0;
    }

    p {
      font-size: 16px;
    }
  }

  .btn {
    font-size: 12px;
    padding: 0;
    color: #74a433;
    font-weight: @headings-font-weight;
    font-family: @headings-font-family;
    text-decoration: none;
  }

  button:focus {
    outline: 0;
  }

  .box-top-left {
    border-right: solid lighten(@gray, 30%) 1px;
    border-bottom: solid lighten(@gray, 30%) 1px;
    width: 300px;
    padding: 20px;
    float: left;
    margin: 0 25px 15px 0;

    @media (max-width: @screen-xs-max) {
      width: 50%;
    }

    @media (min-width: @screen-sm-min) {
      width: 40%;
      margin: 0;
      border-right: none;
      border-bottom: none;
    }
  }

  .main-text {
    padding: 20px;

    @media (min-width: @screen-sm-min) {
      width: 60%;
      float: right;
      border-left: solid lighten(@gray, 30%) 1px;
    }

    .btn {
      font-family: @font-family-sans-serif;
      font-size: 14px;

      @media (max-width: @screen-xs-max) {
        font-size: 10px;
        margin-bottom: 10px;
      }
    }
  }

  &.hcp {
    li {
      margin-bottom: 10px;
    }

    a {
      text-decoration: underline;
      color: @brand-primary;

      &:hover {
        color: darken(@brand-primary, 20%);
      }
    }
  }

  &.patient {
    h5 {
      margin-bottom: 10px;
    }

    p {
      padding-bottom: 5px;
    }

    a {
      color: @brand-primary;
      font-weight: @headings-font-weight;
      font-family: @headings-font-family;
      text-decoration: underline;

      &:hover {
        color: darken(@brand-primary, 20%);
      }
    }
  }
}

.navigation-bar {
  background-color: @brand-primary-light;
  padding: 20px 20px 8px 20px;
  height: auto;
  z-index: 100;
  position: relative;

  svg {
    height: 53px;
    width: 200px;
    float: left;
  }

  @media (max-width: @screen-sm-max) {
    padding: 10px;

    svg {
      .pull-left();
      width: 150px;
    }

    .vcenter {
      display: block;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .logo-info {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;

    .logo-image {
      margin-right: 20px;
    }

    .links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .info-links {
        padding-top: 0px;
        width: -webkit-fill-available;

        a {
          font-size: 11px;
          text-transform: uppercase;
          color: @brand-primary;

          &:hover {
            color: darken(@brand-primary, 10%);
          }

          &:not(:first-child) {
            margin-left: 20px;
          }
        }

        #lets-connect {
          display: inline;
          color: white;
          background-color: #73a233;
          padding-top: 15px;
          padding-bottom: 14px;
          padding-left: 13px;
          padding-right: 13px;
          position: absolute;
          top: -20px;
          width: 112px;
        
          @media (max-width: @screen-md-max) {
            padding-top: 5px;
            padding-bottom: 4px;
            top: 93px;
            width: 100%;
          }        
        }

        #app-download {
          display: inline;
          color: #37607A;
          border: 2px solid #37607A;
          padding-top: 5px;
          padding-bottom: 4px;
          padding-left: 11px;
          padding-right: 11px;
          position: absolute;
          top: -20px;
          right: -207px;
          width: 118px;
          text-align: center;
          text-transform: initial;
          

          @media (max-width: @screen-md-max) {
            top: 121px;
            width: 100%;
            text-transform: uppercase;
            color: #fff;
          }

          &:hover,
          &:active {
            border: 2px solid #37607A;
            background: #37607A;
            color: #fff;
            padding-top: 5px;
            padding-bottom: 4px;
            padding-left: 13px;
            padding-right: 13px;
          }
        }

        .dropdown {
          display: inline-block;
          margin-left: 20px;
        }
      }

      .page-links {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;

        @media (min-width: @screen-md-min) {
          width: 864px;
        }

        .dropdown ul li a {
          text-transform: initial;
        }

        a {
          text-transform: uppercase;
          font-size: 13.7px;
          font-weight: @headings-font-weight;
          font-family: @headings-font-family;
        }

        span {
          height: 16px;
          background-color: @link-color;
          width: 1px;
          margin: 0 8px;
        }

        &.align-right {
          justify-content: flex-start;

          span {
            margin: 2px 8px 0;
          }
        }
      }
    }
  }

  &.hcp {
    .logo-info {
      .links {
        .info-links {
          padding-top: 0;
        
          #app-download {
            right: -160px;
          }
        }

        .page-links {
          padding-top: 0;

          .dropdown {
            display: inline;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .navigation-bar .container {
    position: relative;
  }
}
#lets-connect {
  display: none;
}
@media (max-width: 1199px) {
  #lets-connect {
    display: inline;
    border: 2px solid #73a233;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    top: 93px;
    left: 0px;
    text-align: center;
    width: 100%;
    margin: 0px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "BentonSans-Medium", Helvetica, Arial, sans-serif;
  }
}
#lets-connect.mobile-only {
  background-color: #73a233;
  color: #fff;
  border: 0px;
  position: relative;
  display: block;
  top: 0px;
  font-size: 80%;
}
#app-download {
  display: none;
}
@media (max-width: 1199px) {
  #app-download {
    display: inline;
    border: none;
    background-color: #37607A;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    top: 122px;
    right: 0px;
    left: 0px;
    text-align: center;
    width: 100%;
    margin: 0px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "BentonSans-Medium", Helvetica, Arial, sans-serif;
  }
}
#app-download.mobile-only {
  background-color: #37607A;
  border: 0px;
  position: relative;
  display: block;
  top: 0px;
  font-size: 80%;
}

.white-bg {
  background-color: rgba(255, 255, 255, 0.9);
  width: 85%;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 13px 10px rgba(255, 255, 255, 0.9);
          box-shadow: 0 0 13px 10px rgba(255, 255, 255, 0.9);
  z-index: 90;
  position: relative;
  border-radius: 40px;

  @media (max-width: @screen-sm-max) {
    width: 93%;
    padding-bottom: 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid #37607A;
    border-radius: 0px;
    margin-right: 10px;
    margin-left: 15px;
    padding-left: 0px;

    .container {
      padding-left: 0px;
    }
  }
}

#page-jumps {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0;

  @media (max-width: @screen-sm-max) {
    align-items: start;
    flex-direction: column;
  }

  @media (min-width: @screen-md-min) {
    text-align: center;
    padding-top: 9px;
  }

  a {
    font-size: 85%;
    color: @brand-primary;

    &:focus {
      text-decoration: none;
    }
  }

  .active {
    a {
      text-decoration: underline;
    }
  }

  .divider {
    background-color: @brand-primary;
    height: 14px;
    width: 1px;
    margin: 0 15px;
    display: inline-block;

    @media (max-width: @screen-sm-max) {
      display: none;
    }
  }
}

.blue-bg {
  text-align: center;
}
.blue-bg .container {
  background-color: #37607A;
  width: auto;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}
.blue-bg .container #page-jumps .divider {
  background-color: white;
}
.blue-bg .container #page-jumps a {
  color: white;
}

// .page-jumps {
// 	@media (min-width: @screen-md-min) {
// 		text-align: center;
// 		padding-top: 9px;
// 		margin-bottom: 10px;
// 	}
// 	li {
// 		&:not(:last-child) {
// 			a {
// 				border-right: solid @brand-primary 1px;
// 				margin-right: 15px;
// 				padding-right: 15px;
// 			}
// 		}
// 		&.active {
// 			text-decoration: underline;
// 		}
// 		a, p {
// 			font-size: 90%;
// 			color: @brand-primary;
// 			display: inline;
// 			padding-bottom: 0;
// 			&:focus {
// 				text-decoration: none;
// 			}
// 		}
// 	}
// 	p {
// 		font-weight: @headings-font-weight;
// 		font-family: @headings-font-family;
// 	}
// }

.navbar-collapse {
  // max-height: 340px;
  background-color: #fff;

  li {
    a {
      padding: 0;
    }

    &.bold {
      font-weight: @headings-font-weight;
      font-family: @headings-font-family;

      a {
        color: #74a433;
      }
    }

    &.active {
      a {
        color: @brand-primary !important;
        font-weight: @headings-font-weight;
        font-family: @headings-font-family;
      }
    }
  }

  .list-unstyled {
    li {
      text-transform: initial;
      padding: 3px 0 3px 10px;
      font-size: 80%;
    }
  }

  .langs {
    .btn-sm {
      padding: 3px 8px 1px 8px;
      font-size: 12px;
      color: #fff;
    }
  }
}

// Dropdown resources for button OUTSIDE of nav menu (in page banner sub nav)
.dropdown.resources {
  display: inline;

  a,
  p {
    border: none !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
  }

  .dropdown-menu {
    border-radius: 0;
    padding: 0;
    box-shadow: none;

    @media (max-width: @screen-xs-max) {
      width: 100% !important;
      right: inherit;
    }

    @media (max-width: @screen-sm-max) {
      width: auto;
      right: inherit;
    }

    li {
      font-family: @font-family-sans-serif;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      white-space: nowrap;

      @media (max-width: @screen-sm-max) {
        flex-direction: column;

        p {
          margin: 0 !important;
          padding: 0 !important;
        }

        span.glyphicon-triangle-right {
          display: none;
        }
      }

      @media (max-width: @screen-xs-max) {
        padding: 5px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid @brand-primary-light;
      }

      &:hover {
        background-color: #bedd93;

        a {
          color: @brand-primary;

          span {
            color: @brand-primary;
          }
        }

        p,
        span {
          color: @brand-primary !important;
        }
      }

      .glyphicon {
        margin-right: 20px;
      }

      a,
      .langs,
      p {
        color: #fff !important;
        text-transform: initial;
      }

      .langs {
        width: 116px;

        @media (max-width: @screen-xs-max) {
          width: 100%;
        }

        span {
          min-height: 1px;
          text-decoration: underline;
        }

        a {
          cursor: pointer !important;

          @media (max-width: @screen-sm-max) {
            padding: 0;
          }

          &:not(:first-child) {
            margin-left: 15px;
          }
        }
      }
    }
  }

  a {
    cursor: pointer;
    font-size: 14px;
    font-family: @font-family-sans-serif;

    &:not(:first-child) {
      margin-left: 0;
    }
  }

  span.divider {
    height: 16px;
    background-color: @brand-primary;
    width: 1px;
    margin: 2px 25px -2px 25px;
    display: inline-block;
  }
}

.navbar {
  .navbar-nav {
    padding-left: 0;
    list-style: none;
  }

  .container-fluid {
    padding: 0 15px;
  }

  .nav-brand {
    padding: 13px 15px;
  }

  svg {
    height: 40px;
    width: auto;
  }

  li {
    padding: 6px 12px;
    font-size: 15px;

    &.bold {
      font-weight: @headings-font-weight;
      font-family: @headings-font-family;
      text-transform: uppercase;
    }
  }

  .glyphicon {
    font-weight: normal;
    margin-left: 10px;
  }

  .collapsed {
    .glyphicon {
      transform: rotate(180deg);
    }
  }

  .navbar-collapse {
  .navbar-nav.mobile-accordion {
      text-transform: initial;

      li {
        padding-left: 30px;
      }

      li a {
        color: #37607A;
      }
    }
  }
}

.active {
  text-decoration: none;
  color: @brand-primary;
}

.navbar-brand {
  @media (max-width: @screen-sm-max) {
    padding: 11.5px 15px !important;
  }
}

.navbar-fixed-top.navigation-mobile {
  @media (max-width: @screen-sm-max) {
    position: relative;
    z-index: 50;
    top: 130px;
  }
}

// .navbar-fixed-top {
// 	border-bottom: solid @brand-primary 1px;
// }

.navbar-nav {
  margin: 0 -15px;
}

.navbar-default {
  border: none;
  border-bottom: @brand-primary solid 1px;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: @brand-primary;
}

header {
  a {
    color: @brand-primary;

    &:hover {
      color: darken(@brand-primary, 10%);
    }
  }
}

footer {
  padding: 20px 20px 15px 20px;
  border-top: 1px @brand-primary solid;
  background-color: @brand-primary;
  background-image: url("../../../assets/img/2022/global/ruconest-footer-backgroundmobile.png"), -webkit-gradient(linear, left top, left bottom, from(#ebeff1), color-stop(2%, #ebeff1), color-stop(49%, #ebeff1), color-stop(79%, #ebeff1), to(#dde1e3));
  background-image: url("../../../assets/img/2022/global/ruconest-footer-backgroundmobile.png"), -o-linear-gradient(top, #ebeff1 0%, #ebeff1 2%, #ebeff1 49%, #ebeff1 79%, #dde1e3 100%);
  background-image: url("../../../assets/img/2022/global/ruconest-footer-backgroundmobile.png"), linear-gradient(180deg, #ebeff1 0%, #ebeff1 2%, #ebeff1 49%, #ebeff1 79%, #dde1e3 100%);
  background-size: 100% 116px, 100% 69%;
  background-repeat: no-repeat;
  background-position: right 56%, left top;
  position: relative;

  @media (min-width: @screen-sm) {
    background-size: 33% 50%, 100% 48%;
    background-repeat: no-repeat;
    background-position: right top;
  }

  @media (min-width: @screen-md) {
    padding: 20px 20px 20px 20px;
    background-color: #37607A;
    background-image: url("../../../assets/img/2022/global/ruconest-footer-backgrounddesktop.png"), -webkit-gradient(linear, left top, left bottom, from(#ebeff1), color-stop(2%, #ebeff1), color-stop(49%, #ebeff1), color-stop(79%, #dde1e3), to(#c6cacb));
    background-image: url("../../../assets/img/2022/global/ruconest-footer-backgrounddesktop.png"), -o-linear-gradient(top, #ebeff1 0%, #ebeff1 2%, #ebeff1 49%, #dde1e3 79%, #c6cacb 100%);
    background-image: url("../../../assets/img/2022/global/ruconest-footer-backgrounddesktop.png"), linear-gradient(180deg, #ebeff1 0%, #ebeff1 2%, #ebeff1 49%, #dde1e3 79%, #c6cacb 100%);
    background-size: 380px 100%, 68% 100%;
    background-position: 57.5% top, 0 0;
  }

  @media (min-width: 1024px) {
    background-size: 380px 100%, 63% 100%;
    background-position: 55.5% top, 0 0;
  }

  @media (min-width: @screen-md-max) {
    background-size: 380px 100%, 61% 100%;
    background-position: 54.5% top,0 0;
  }

  .container {
    z-index: 20;
    position: relative;
  }

  @media (min-width: @screen-md) {
    .colorblock-blueleft {
      background: #37607A;
      position: absolute;
      width: 32%;
      left: 68%;
      top: 0px;
      height: 100%;
      z-index: 10;
    }
  }

  .text {
    font-size: 80%;

    @media (min-width: @screen-sm) {
      margin-right: 70px;
    }

    .links {
      .list-inline {
        text-align: left;

        a {
          font-weight: 700;
          font-size: 11px;
        }
      }
    }

    .condensed,
    p {
      font-size: 75%;
    }
  }

  .links {
    &.social-icons {
      margin-right: -20px;
      margin-left: -20px;
      color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 88px 20px 0px 20px;
    
      @media (min-width: @screen-md) {
        margin-top: -20px;
        padding: 20px;
        float: right;
        width: 36.5%;
      }

      .uppercase {
        text-transform: uppercase;
        width: 125px;
        font-weight: 700;
        font-family: "BentonSans-Medium", Helvetica, Arial, sans-serif;
        text-align: left;
      }

      .column-social {
        img {
          width: 33px;
        }

        .instagram img {
          width: 29px;
          margin-right: 4px;
        }

        p {
          margin-bottom: 5px;
          font-weight: 700;
          font-size: 86%;
        }
      }

      .list-inline {
        margin-right: 0px;
      }
    }
  }

  a {
    color: @brand-primary;

    &:hover {
      color: darken(@brand-primary, 10%);
    }
  }

  img {
    height: 51px;
    width: 220px;

    @media (max-width: @screen-sm-max) {
      padding-bottom: 20px;
      height: 70px;
      width: 230px;
    }
  }

  ul {
    .text-right();

    @media (max-width: @screen-sm-max) {
      .text-left();
    }
  }

  li {
    font-size: 66%;
  }

  @media (min-width: @screen-sm) {
    .logo {
      float: left;
    }
  }

  @media (min-width: @screen-md-min) {
    .text {
      float: left;
      padding-left: 20px;
    }

    .links {
      .text-right();
      padding-top: 0;
    }
  }
}

section,
#one,
#one-one,
#one-two,
#two,
#two-one,
#two-two,
#three-one #three,
#four,
#five {
  &:focus {
    outline: none;
  }
}

//
// POPOVERS AND MODALS
//

.popover-content {
  margin-left: 10px;

  span {
    &:nth-child(3n + 1) {
      font-weight: @headings-font-weight;
      font-family: @headings-font-family;
      margin-left: -10px;
    }

    &:nth-child(3n + 2) {
      font-style: italic;
    }
  }
}

.modal {
  padding-right: 0 !important;

  .modal-header {
    img {
      .pull-right();

      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-content {
    border-width: 4px;
    border-radius: 0;
    text-align: center;
  }

  h3 {
    color: @brand-primary;
    margin-bottom: 30px;
  }

  .modal-body {
    padding: 60px 10px 120px 10px;

    .btn {
      &:last-child {
        @media (min-width: 1201px) {
          margin-left: 30px;
        }

        @media (max-width: 1200px) {
          margin-top: 10px;
        }
      }
    }

    &.slideshow-container {
      padding: 20px 10px 75px 10px;
    }
  }

  &.leaving-ruconest-modal {
    .modal-body {
      .btn {
        &:last-child {
          margin-top: 0px;
          margin-left: 30px;
        }
      }
    }
  }


}

.reimbursement-request-form.modal .close-button {
  float: right;
  margin: 0px 0px 20px 20px;
}
.reimbursement-request-form.modal .modal-content {
  border-width: 0px;
  background: #ffffff;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#edf9ff));
  background: -o-linear-gradient(top, #ffffff 0%, #edf9ff 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #edf9ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#edf9ff', GradientType=0);
  padding: 50px 60px;
}
.reimbursement-request-form.modal .modal-body {
  text-align: left;
}
@media (min-width: 992px) {
  .reimbursement-request-form .modal-dialog {
    width: 756px;
  }
}

.slideshow {
  margin: 0 30px;

  @media (min-width: @screen-lg-min) {
    margin: 0 150px;
  }

  img {
    max-width: 100%;
    display: inline;

    @media (min-width: @screen-lg-min) {
      max-height: 800px;
    }
  }
}

#reload_moa {
  padding: 2px 8px 0 8px !important;
  font-size: 13px !important;
  margin-bottom: 30px;
}

.symbol {
  font-family: Arial;
  bottom: -0.1em;
  line-height: 0;
  position: relative;
}

.ref-spot {
  background-color: transparent;
  height: 20px;
  width: 10px;
  float: right;
  margin-top: 25px;

  @media (min-width: @screen-md-min) {
    display: block;
  }

  @media (max-width: @screen-sm-max) {
    position: absolute;
    margin-top: 11px;
    margin-left: -8px;
  }

  @media (max-width: @screen-xs-max) {
    position: absolute;
    margin-top: 11px;
    right: 11px;
  }

  a {
    color: transparent;
  }
}

a.anchor {
  display: block;
  position: relative;
  visibility: hidden;
  // @media (min-width: @screen-md-min) {
  //   top: -@navbar-large-height;
  // }
  // @media (max-width: @screen-sm-max) {
  //   top: -@navbar-height;
  // }
  // &.sub {
  // 	@media (min-width: @screen-md-min) {
  // 		top: -60;
  // 	}
  // }
}

.panel {
  box-shadow: none;
}

.grey-button .vjs-big-play-button {
  border: 0.06666em solid #ddd !important;
}

.video-js.grey-button:hover .vjs-big-play-button,
.video-js.grey-button .vjs-big-play-button:focus {
  border-color: #ddd;
}

.video-js.grey-button .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js.grey-button .vjs-play-control .vjs-icon-placeholder:before {
  color: #ddd !important;
}

.container {
  max-width: 100%;
}

a.underline {
  text-decoration: underline;
}

#expandable-footer.patient strong a {
  font-family: @font-family-condensed-bold;
}

#slidershow {
  h2,
  h3 {
    color: #4f758b;
  }

  h2,
  h3 {
    color: #4f758b;
  }

  .video-subtext {
    color: #74a433;
    font-weight: 600;
    text-align: center;
  }
}

#symptom-relief h2 {
  text-align: left;
}

#symptom-relief .quote {
  justify-content: left;
  text-align: left;
  padding: 80px;
}

@media only screen and (max-width: 991px) {
  #symptom-relief .marketing-item {
    padding-left: 0;
  }

  #symptom-relief .quote {
    padding: 30px;
    padding-left: 0;
  }
}

#hcp-quote .marketing-item {
  text-align: left;
  padding-right: 73px;
  width: 56%;
}

#hcp-quote .quote {
  justify-content: center;
  text-align: left;
  padding: 0 0 0 80px;
}

@media only screen and (max-width: 991px) {
  #hcp-quote .marketing-item {
    padding-left: 0;
    width: 100%;
  }

  #hcp-quote .quote {
    padding: 30px;
    padding-left: 0;
    padding-bottom: 0;
  }

  #hcp-quote br {
    display: none;
  }
}

#swell-triggers .marketing-item {
  text-align: left;
  padding-right: 73px;
  width: 56%;
}

#swell-triggers .quote {
  justify-content: center;
  text-align: left;
  padding: 0 0 0 80px;
}

@media only screen and (max-width: 991px) {
  #swell-triggers .marketing-item {
    padding-left: 0;
    width: 100%;
  }

  #swell-triggers br {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  #swell-triggers .arrows {
    column-count: 1;
  }
}

.navigation-bar .logo-info .links .info-links a:not(:first-child) {
  margin-left: 11px;
}

#discussion-modal {
  .close {
    position: relative;
    z-index: 5;
  }
}

#last-item p strong {
  text-transform: none;
}

.m-l-70 {
  margin-left: 70px !important;
}

.w-65 {
  width: 65% !important;
}

#slider-container {
  margin-top: 45px;
}

@media only screen and (max-width: 991px) {
  #slider-container {
    margin-top: 5px;
  }

  .m-t-s-14 {
    margin-top: 14px !important;
  }
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

@media only screen and (max-width: 991px) {
  .m-t-s-35 {
    margin-top: 35px !important;
  }
}

.page-green {
  background: linear-gradient(to bottom, #ffffff 0%, #e1ecd4 100%);
}

.slick-dots li button:before {
  font-size: 60px;
}

.slick-dots li.slick-active button:before,
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  color: #73a233;
}

@media (max-width: 1024px) {
  .page-content.home .container.shadow-slider .shadow-slider__slide img {
    display: block;
    width: 100%;
    max-width: 520px;
    height: auto;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .m-top-20-md {
    margin-top: 20px;
  }

  .left-nudge {
    margin-left: -12px;
  }
}

.d-flex {
  display: flex;
}

.flx-dir-col {
  flex-direction: column;
}

.algn-itms-cntr {
  align-items: center;
}
.slick-dots {
  bottom: 25px;
}

@media (max-width: 991px) {
  .slick-dots {
    bottom: 0px;
  }
}

.slick-dots {
  bottom: 25px;
}

@media (max-width: 991px) {
  .slick-dots {
    bottom: 0px;
  }
}
.slick-dots li button:before {
  font-size: 60px;
}

.slick-dots li.slick-active button:before,
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  color: #73a233;
}

.page-content.home .container.shadow-slider .shadow-slider__slide {
  .video-container .video-player video {
    outline: none;
  }
  @media (max-width: 1024px) {
    img {
      display: block;
      width: 100%;
      max-width: 520px;
      height: auto;
      margin: auto;
    }
  }
}



#discussion-modal .modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  margin: 0 auto;

  #discussion-modal-image {
    display: inline-flex;
  }

  @media only screen and (max-width: 1199px) {
    width: 39%;

    .row h5 {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 50%;
  }

  @media only screen and (max-width: 657px) {
    width: 60%;
  }

  @media only screen and (max-width: 570px) {
    width: 70%;
  }

  @media only screen and (max-width: 469px) {
    width: 80%;
  }

  @media only screen and (max-width: 411px) {
    width: 90%;
  }

  @media only screen and (max-width: 321px) {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .m-top-15-md {
    margin-top: 15px;
  }

  .left-nudge {
    margin-left: 0px;
  }
}

.d-flex {
  display: flex;
}

.flx-dir-col {
  flex-direction: column;
}

.algn-itms-cntr {
  align-items: center;
}

.width-14-prcnt {
  width: 14%;
}
.width-10-prcnt {
  width: 10%;
}

@media only screen and (max-width: 600px) {
  .col-1-up-sm {
    column-count: 1 !important;
  }
}

.discussion-download:focus,
.discussion-download:active:focus,
.discussion-download:visited {
  outline: none !important;
  outline-offset: 0 !important;
  background-color: #74a433;
}

@media only screen and (max-width: 600px) {
  .w-100-sm {
    width: 100% !important;
  }
}

.slick-slide {
  outline: none;
}
@media only screen and (max-width: 991px) {
  #slidershow img {
    margin-top: 20px;
  }

  .p-top-60-sm {
    padding-top: 60px !important;
  }
}

.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  outline-color: #2f4315;
}

#haeapp.banner-content {
  background: #37607A;
}

#haeapp.banner-content .container-split {
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (max-width: 600px) {
  #haeapp.banner-content .container-split {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#haeapp.banner-content .split-left-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#haeapp.banner-content .side-left {
  width: 55%;
  padding-right: 2%;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-align: center;
  max-height: 120px;
  border-right: 1px solid #fff;
}

@media only screen and (max-width: 991px) {
  #haeapp.banner-content .side-left {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  #haeapp.banner-content .side-left {
    -ms-flex-wrap: initial;
        flex-wrap: initial;
  }
}

#haeapp.banner-content .side-left img {
  -ms-flex-item-align: end;
      align-self: end;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 60px;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  #haeapp.banner-content .side-left img {
    -ms-flex-item-align: center;
        align-self: center;
  }
}

#haeapp.banner-content .side-left h2 {
  color: #fff;
  margin-bottom: 0px;
  font-weight: 700;
  font-family: "BentonSans-Medium", Helvetica, Arial, sans-serif;
  font-size: 240%;
}

@media only screen and (max-width: 1349px) {
  #haeapp.banner-content .side-left h2 {
    font-size: 230%;
  }
}

@media only screen and (max-width: 1199px) {
  #haeapp.banner-content .side-left h2 {
    font-size: 181%;
  }
}

@media only screen and (max-width: 991px) {
  #haeapp.banner-content .side-left h2 {
    font-size: 120%;
  }
}

@media only screen and (max-width: 600px) {
  #haeapp.banner-content .side-left h2 {
    font-size: 75%;
  }
}

#haeapp.banner-content .side-left span {
  color: #fff;
  font-family: "BentonSansCond-Book", Helvetica, Arial, sans-serif;
  font-size: 171%;
}

@media only screen and (max-width: 1349px) {
  #haeapp.banner-content .side-left span {
    font-size: 151%;
  }
}

@media only screen and (max-width: 1199px) {
  #haeapp.banner-content .side-left span {
    font-size: 120%;
  }
}

@media only screen and (max-width: 991px) {
  #haeapp.banner-content .side-left span {
    font-size: 75%;
  }
}

@media only screen and (max-width: 600px) {
  #haeapp.banner-content .side-left span {
    font-size: 56%;
  }
}

#haeapp.banner-content .side-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      align-self: center;
  width: 45%;
  padding-left: 2%;
}

@media only screen and (max-width: 1034px) {
  #haeapp.banner-content .side-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

#haeapp.banner-content .side-right p {
  color: #fff;
  padding: 0;
  font-family: "BentonSansCond-Book", Helvetica, Arial, sans-serif;
  font-size: 85%;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  #haeapp.banner-content .side-right p {
    text-align: center;
  }
}

@media only screen and (min-width: 1034px) {
  #haeapp.banner-content .side-right p {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 1199px) {
  #haeapp.banner-content .side-right p {
    font-size: 85%;
  }
}

@media only screen and (max-width: 991px) {
  #haeapp.banner-content .side-right p {
    font-size: 85%;
  }
}

@media only screen and (max-width: 600px) {
  #haeapp.banner-content .side-right p {
    font-size: 55%;
  }
}

#haeapp.banner-content .side-right button,
#haeapp.banner-content .side-right a {
  background-color: #fff;
  border: 0px;
  border-radius: 1px;
  margin-right: 0px;
  height: 40px;
  font-weight: 700;
  font-family: "BentonSans-Bold", Helvetica, Arial, sans-serif;
  font-size: 20px;
  -ms-flex-item-align: center;
      align-self: center;
  width: 32%;
  color: #37607a;
  text-align: center;
  line-height: 42px;
  margin-left: 4%;
  max-width: 200px;
}

@media only screen and (max-width: 991px) {
  #haeapp.banner-content .side-right button,
  #haeapp.banner-content .side-right a {
    width: 60%;
    font-size: 16px;
    margin-left: 0%;
  }
}

.b-r-1 {
  border-right: 1px solid;
}

.b-l-1 {
  border-left: 1px solid;
}

.icon_email,
.icon_phone {
  width: 40px;
  display: inline-block;
  padding-right: 10px;
}

.icon_email svg,
.icon_phone svg {
  width: 100%;
  margin-bottom: -4px;
}

.text--black {
  color: #000;
}

.text--brandblue {
  color: #37607A;
}

.text--smaller {
  font-size: .85em;
}

.text--uppercase {
  text-transform: uppercase;
}

.contactForm--uppercase {
  text-transform: uppercase;
  color: #a8ada3;
  margin-bottom: 10px;
}

.contactForm--lightinput {
  background-color: white;
  border: 1px solid #a8ada3;
  padding: 10px;
  margin-bottom: 10px;
}

.learnmoreboxes__list,
.contactCards--flex,
.greenboxes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.contactCards,
.learnmoreboxes__list,
.greenboxes {
  list-style: none;
  padding-left: 0px;
}

.contactCards .contactCards__title,
.learnmoreboxes__list .contactCards__title,
.greenboxes .contactCards__title,
.contactCards .greenboxes__title,
.learnmoreboxes__list .greenboxes__title,
.greenboxes .greenboxes__title {
  font-family: "BentonSans-Bold", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #37607A;
  font-size: 20px;
}

.contactCards .contactCards__image,
.learnmoreboxes__list .contactCards__image,
.greenboxes .contactCards__image {
  width: 280px;
  height: 280px;
  background-color: #a8ada3;
}

.contactCards .contactCards__bio,
.learnmoreboxes__list .contactCards__bio,
.greenboxes .contactCards__bio {
  margin-left: 20px;
}

.contactCards .contactCards__firstname_lastname,
.learnmoreboxes__list .contactCards__firstname_lastname,
.greenboxes .contactCards__firstname_lastname {
  font-family: "BentonSans-Bold", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #6BA43A;
}

.contactCards .contactCards__firstname,
.learnmoreboxes__list .contactCards__firstname,
.greenboxes .contactCards__firstname {
  font-family: "BentonSans-Bold", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #37607A;
}

.contactCards .contactCards__widthphoto,
.learnmoreboxes__list .contactCards__widthphoto,
.greenboxes .contactCards__widthphoto,
.contactCards .contactCards__slim,
.learnmoreboxes__list .contactCards__slim,
.greenboxes .contactCards__slim,
.contactCards .contactCards__event,
.learnmoreboxes__list .contactCards__event,
.greenboxes .contactCards__event {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px;
  color: #000;
}

.contactCards .contactCards__widthphoto,
.learnmoreboxes__list .contactCards__widthphoto,
.greenboxes .contactCards__widthphoto {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.contactCards .contactCards__slim,
.learnmoreboxes__list .contactCards__slim,
.greenboxes .contactCards__slim,
.contactCards .contactCards__event,
.learnmoreboxes__list .contactCards__event,
.greenboxes .contactCards__event,
.contactCards .learnmoreboxes__container,
.learnmoreboxes__list .learnmoreboxes__container,
.greenboxes .learnmoreboxes__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 3px solid #6BA43A;
}

.contactCards .learnmoreboxes__container,
.learnmoreboxes__list .learnmoreboxes__container,
.greenboxes .learnmoreboxes__container {
  -ms-flex-preferred-size: 32%;
      flex-basis: 32%;
  padding: 20px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 30px;
}

.contactCards .learnmoreboxes__container .learnmoreboxes__containerinner,
.learnmoreboxes__list .learnmoreboxes__container .learnmoreboxes__containerinner,
.greenboxes .learnmoreboxes__container .learnmoreboxes__containerinner {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contactCards .learnmoreboxes__container .learnmoreboxes__lefthand,
.learnmoreboxes__list .learnmoreboxes__container .learnmoreboxes__lefthand,
.greenboxes .learnmoreboxes__container .learnmoreboxes__lefthand {
  height: 147px;
  padding-right: 20px;
}

.contactCards .learnmoreboxes__container .learnmoreboxes__righthand,
.learnmoreboxes__list .learnmoreboxes__container .learnmoreboxes__righthand,
.greenboxes .learnmoreboxes__container .learnmoreboxes__righthand {
  -ms-flex-preferred-size: 60%;
      flex-basis: 60%;
}

.contactCards .greenboxes__slim,
.learnmoreboxes__list .greenboxes__slim,
.greenboxes .greenboxes__slim {
  background-color: #6BA43A;
  -ms-flex-preferred-size: 48%;
      flex-basis: 48%;
  color: white;
  padding: 20px;
}

.contactCards .greenboxes__slim ul,
.learnmoreboxes__list .greenboxes__slim ul,
.greenboxes .greenboxes__slim ul {
  list-style-type: '- ';
}

.contactCards .greenboxes__slim strong,
.learnmoreboxes__list .greenboxes__slim strong,
.greenboxes .greenboxes__slim strong,
.contactCards .greenboxes__slim li,
.learnmoreboxes__list .greenboxes__slim li,
.greenboxes .greenboxes__slim li {
  color: white;
}

.contactCards .contactCards__event,
.learnmoreboxes__list .contactCards__event,
.greenboxes .contactCards__event {
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
  margin-top: 60px;
}

.contactCards .contactCards__link,
.learnmoreboxes__list .contactCards__link,
.greenboxes .contactCards__link,
.contactCards .learnmoreboxes__link,
.learnmoreboxes__list .learnmoreboxes__link,
.greenboxes .learnmoreboxes__link {
  margin-bottom: -40px;
  background-color: #37607A;
  color: white;
  text-align: center;
  -webkit-filter: none;
          filter: none;
}

.contactCards .contactCards__link a,
.learnmoreboxes__list .contactCards__link a,
.greenboxes .contactCards__link a,
.contactCards .learnmoreboxes__link a,
.learnmoreboxes__list .learnmoreboxes__link a,
.greenboxes .learnmoreboxes__link a {
  color: white;
}

.contactCards .contactCards__link,
.learnmoreboxes__list .contactCards__link,
.greenboxes .contactCards__link {
  width: 60%;
  -ms-flex-item-align: center;
      align-self: center;
  margin-bottom: -40px;
}

.contactCards .learnmoreboxes__link,
.learnmoreboxes__list .learnmoreboxes__link,
.greenboxes .learnmoreboxes__link {
  -ms-flex-item-align: center;
      align-self: center;
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 991px) {
  .learnmoreboxes__list,
  .greenboxes {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.background--white {
	background: none;
	background-color: #fff;
}

.background--lightblue {
	background: none;
	background-color: rgba(57,95,121,0.07);
}

.page-content.banner.home:not(.hcp) {
	background-image: url('/wp-content/uploads/HeaderImage-scaled.jpg') !important;
	background-position: top left;
	margin-left: -8px;
	margin-right: 0px;
  height: 685px;
  background-size: auto 684px;
}

@media only screen and (max-width: 767px) {
	.page-content.banner.home .header__green {
		font-size: 20px;
		width: 100%;
	}
	
	.page-content.banner.home:not(.hcp) {
		/* background-image: url('/wp-content/uploads/Ruconest-HCP-Site-10th-Anniversary-Homepage-Hero-Banner.jpg') !important; */
		background-position: top center;
		margin-top: 119px;
		height: 830px;
		margin-left: -8px;
		margin-right: -8px;
	}
}

@media (max-width: 767px) {
  .page-content.banner.home .header-section__fullimage {
    display: flex;
  }

  .header-section__fullimage {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .home.hcp .header-section__fullimage {
    padding-top: 80px;
  }
}

.page-content.banner.home:not(.hcp) .container {
	width: 100%;
}

/*
.page-content.banner.home:not(.hcp) .text-center {
	max-width: 360px;
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	height: 100%;
}
*/

@media only screen and (max-width: 767px) {
	.page-content.banner.home:not(.hcp) .text-center {
		height: 67%;
		max-width: 9999px;
	}
}
	
.header__white {
	color: #fff;
}


.common__areas__element {

  img {
    width: 100%;
  }

  .sub__area__element {
    display: block;
    line-height: 2em;
    margin-top: 20px;
    text-align: center;

    @media (min-width: @screen-md-min) {
      position: relative;
      top: -120px;
      right: -320px;
      text-align: left;
    }
  }
}