@keyframes lds-eclipse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes lds-eclipse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

#videos-loading{
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    .lds-eclipse {
        position: relative;
    }
    .lds-eclipse div {
        position: absolute;
        -webkit-animation: lds-eclipse 1s linear infinite;
        animation: lds-eclipse 1s linear infinite;
        width: 160px;
        height: 160px;
        top: 20px;
        left: 20px;
        border-radius: 50%;
        box-shadow: 0 4px 0 0 #93dbe9;
        -webkit-transform-origin: 80px 82px;
        transform-origin: 80px 82px;
    }
    .lds-eclipse {
        width: 200px !important;
        height: 200px !important;
        -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
        transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    }
}

.video-lib-cats{
    position: relative;
    background-color: #fff;
    height: 52px;
    padding: 0 50px;
    border: 1px solid #D3DCE2;
    border-radius: 50px;
    overflow: hidden;
    margin-bottom: 50px;
}

.video-lib-cats-list__option{
    position: relative;
    height: 52px;
    text-align: center;
    line-height: 52px;
    outline: none;
    cursor: pointer;
    transition: color .5s;

    &:before{
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: #D3DCE2;
        @media (max-width: 568px) {
			display: none;
		}
    }

    &:first-child:before{
        display: none;
    }

    &:hover,
    &.slick-current{
        color: #74A433;
    }

    @media (max-width: 568px) {
        &:hover{
            color: inherit;
        }
    }
}

.video-lib-cats__nav{
    position: absolute;
    width: 50px;
    height: 52px;
    cursor: pointer;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    &.slick-hidden{
        display: flex;
    }
    &.slick-hidden > svg, 
    &.slick-disabled > svg{
        opacity: .3;
    }
}

.video-lib-cats__nav--left{
    left: 0;
}
.video-lib-cats__nav--right{
    right: 0;
}

.video20-list-wrap .videos-list {
  min-height: 400px;
  width: 100%;
}
@media (min-width: 992px) {
  .video20-list-wrap .videos-list {
    width: 102%;
  }
}
.btn-videos-loadmore {
  color: #fff;
  background-color: #6BA43A;
  margin: 0 auto;
  border: none;
  display: block;
  border-radius: 5px;
}
@media (max-width: 991px) {
  .video20-mobile-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow: auto;
  }
  .video20-mobile-container .video20-lib-cats-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .video20-mobile-container .video20-lib-cats-group .video20-lib-cats-group-single__option {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
}
.video20-lib-cats-group .video20-lib-cats-group-single__option {
  border: 1px solid #37607A;
  background-color: #D1DDE3;
  color: #37607A;
  margin-right: 15px;
  border-radius: 35px;
  padding: 12px 20px 10px;
  font-size: .8em;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: "BentonSans-Medium", Helvetica, Arial, sans-serif;
  -webkit-text-stroke: 0.25px;
}
.video20-lib-cats-group .video20-lib-cats-group-single__option.is-checked {
  background-color: #37607A;
  color: #fff;
}

// --- VIDEO --- 
.video {
    outline: none;
    margin: 20px 0px;
    
    @media (max-width: @screen-xs) {
        margin: auto;
        margin-bottom: 20px;
    }

    @media (min-width: @screen-sm) { 
        min-height: 270px;
        margin-right: 2%;
    }

    @media (max-width: @screen-sm-max) {
        width: 100%;
    }

    @media (min-width: @screen-md) {
        width: 31.33%;
    }
}

.video .video-title {
    color: #979797;
    text-align: left;
    font-size: 15px;
    line-height: 20px;
    margin: 0px 0 5px;
    font-weight: @headings-font-weight;
    font-family: @headings-font-family;
}

.video .video-desc {
    color: #9A9A9A;
    font-size: 12px;
    font-family: 'BentonSans-Regular';
    margin-left: 5px;
}

.video .video-category {
  color: #979797;
  font-size: 12px;
  text-decoration: underline;
  text-align: left;
}
.video-box {
  position: relative;
  margin: auto;
}
@media (max-width: 991px) {
  .video-box {
    width: 100%;
    margin-bottom: 30px;
  }
}
.video.hidden {
  display: none;
}

.video-thumb {
    position: relative;
    background-color: lightgrey;
    margin-bottom: 15px;
    overflow: hidden;
    height: 190px;

    @media (max-width: 1199px) {
        width: 100%;
        height: 270px;
    }
}

@media (min-width: 992px) {
    .video-thumb {
      height: 190px;
    }
  }
  @media only screen and (max-device-width: 1024px) {
    .video-thumb:hover .video-thumb__bg {
        filter: grayscale(80%);
        transform: none;
    }
  }

.video-thumb__bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    transition: .25s;

    &--default{
        background-size: 170px auto;
    }
}

/*
.video-thumb:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(56, 96, 121, .85);
    z-index: 10;
    transition: opacity .5s;
}
*/

.video-thumb__play-btn{
    position: absolute;
    width: 61px;
    height: 61px;
    left: ~"calc(50% - 30.5px)";
    top: ~"calc(50% - 30.5px)";
    border-radius: 100%;
    z-index: 20;
    background: rgba(255,255,255, .4);
    border: 2px solid transparent;
    transition: .5s;

    @media (max-width: @screen-xs) {
        width: 87px;
        height: 87px;
        left: ~"calc(50% - 43.5px)";
        top: ~"calc(50% - 43.5px)";
        border: 4px solid #fff;
        background: transparent;
    }
}
.video-thumb__play-btn:before{
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 20px;
    border-color: transparent transparent transparent #ffffff;
    position: absolute;
    top: ~"calc(50% - 14px)";
    left: ~"calc(50% - 7px)";
}

.video-thumb:hover:before{
    opacity: .4;
    @media only screen and (max-device-width : 1024px) { 
        opacity: 1;
    }
}

.video-thumb:hover .video-thumb__play-btn{
    background: transparent;
    border: 2px solid rgba(255,255,255, .4);
}

.video .video-content{
    display: none;
}

.video-list-wrap{
    position: relative;
}

.video-list-wrap .videos-list{
    width: ~"calc(100% - 90px)";
    margin: 0 auto;
    margin-bottom: 50px;
    @media (max-width: @screen-xs) {
        width: 100%;
    }
}

.video-list-wrap .arrow{
    position: absolute;
    display: block;
    outline: none;
    width: 16px;
    height: 30px;
    z-index: 50;
    cursor: pointer;
    top: 63px;
    background-repeat: no-repeat;
    background-size: contain;

    &--prev{
        left: 0;
        background-image: url("../../img/arrow-video-left.svg");
    }
    &--next{
        right: 0;
        background-image: url("../../img/arrow-video-right.svg");
    }

    &.slick-hidden{
        display: none;
    }

    &.slick-disabled{
        opacity: .3;
    }

    @media (max-width: @screen-xs) {
        display: none;
    }
}
@media (max-width: 675px) {
    .video-list-wrap .arrow{
        display: none !important;
    }
}

#video-modal{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(56, 96, 121, .9);
    z-index: 99999;
    overflow: auto;

    &.active{
        display: block;
    }

    .video-modal__close{
        position: absolute;
        top: 0;
        right: -30px;
        width: 24px;
        height: 24px;
        background-image: url('../../img/close-video-modal.svg');
        background-repeat: no-repeat;
        cursor: pointer;

        &.off{
            pointer-events: none;
        }

        @media (max-width: @screen-sm-max) {
            right: 10px;
            top: 0;
        }
    }

    .container{
        position: relative;
        margin: 50px auto;
        // top: 50%;
        // transform: translateY(-50%);
        // @media (max-width: 568px) {
        //     top: 0;
        //     transform: none;
        //     margin: 50px auto;
        // }
    }

    .video-placeholder{
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin-bottom: 20px;

        &.default-thumb{
            background-size: 50% auto;
            background-position: 50%;
        }
    }

    .video-placeholder iframe,
    .video-placeholder video{
        display: block;
        width: 100%;
        height: 70vh;
    }

    .video-placeholder video{
        background-color: #000;
    }
    // .video-placeholder video{
    //     width: 100%;
    //     height: auto;
    // }


    .video-cat-name{
        font-weight: @headings-font-weight;
        font-family: @headings-font-family;
        font-size: 18px;
        color: #fff;
        margin-bottom: 20px;
        line-height: 16px;
        @media (max-width: @screen-sm-max) {
            padding-right: 30px;
        }
    }
    .video-title{
        font-weight: @headings-font-weight;
        font-family: @headings-font-family;
        font-size: 21px;
        color: #fff;
        margin-bottom: 15px;
        line-height: 18px;
    }
    .video-desc{
        font-family: 'BentonSans-Regular';
        font-size: 14px;
        color: #fff;
        line-height: 19px;
    }
}
.logo-button{
    background-color: #fff;
    border: solid 1px #D3DCE2;
    border-radius: 10px;
    margin-left: 45px;
    margin-right: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @media (max-width: 769px) {
       margin-left: 60px;
       margin-right: 60px;
       flex-direction: column;
    }
    @media (max-width: 675px) {
        margin-left: 45px;
        margin-right: 45px;
     }
     @media (max-width: 480px) {
        margin-left: 0px;
        margin-right: 0px;
     }
    .logo-link{
        width: 50%;
        padding-top: 30px;
        padding-bottom: 30px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 769px) {
            width: 100%;
            padding: 0;
        }
    }
    img{
        width: 80%;
        height: auto;
        @media (max-width: @screen-xs) {
            width: 250px;
            padding-top: 30px;
            padding-bottom: 30px;
            display: block;
        }
        @media (max-width: 769px) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
    .green-part{
        width: 50%;
        background-color: #74A332;
        height: 100%;
        display: block;
        display: flex;
        align-items: flex-start;
        position: relative;
        flex-direction: column;
        @media (max-width: 769px) {
            width: 100%;
        }
        &:before {
            content:"";
            position:absolute;
            right: -34px;
            width: 0; 
            height: 0; 
            top: 0;
            border-top: 42px solid transparent;
            border-bottom: 42px solid transparent;
            border-left: 35px solid #74A332;
            @media (max-width: 769px) {
                display: none;
            }
        }
        &:after{
            content:"";
            position:absolute;
            right: -5px;
            top: 25px;
            width: 16px; 
            height: 30px; 
            background-image: url('../../img/arrow.svg');
            z-index: 15;
            @media (max-width: 769px) {
                display: none;
            }
        }
    }
    .green-part__title{
        color: #fff;
        font-size: 18px;
        font-family: @headings-font-family;
        line-height: 1.2;
        padding-bottom: 0;
        margin-bottom: 20px;
        padding-top: 20px;
        margin-left: 0;
        padding-left: 30px;
        @media (max-width: 769px) {
            font-size: 17px;
            display: block;
            margin-left: 0;
            width: 100%;
        }
    }
    .green-part__text{
        display: inline-table;
        color: #fff;
        font-size: 16px;
        margin-left: 0;
        font-family: @font-family-sans-serif;
        line-height: 1.3;
        padding-bottom: 0;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 30px;
        @media (max-width: 769px) {
            font-size: 16px;
            display: block;
            margin-left: 0;
            width: 100%;
        }
    }
}