.video-top-teaser{
    position: relative;
    z-index: 5;
    background: #82090d;
    background: -moz-linear-gradient(left, #82090d 0%, #b8151e 100%);
    background: -webkit-linear-gradient(left, #82090d 0%,#b8151e 100%);
    background: linear-gradient(to right, #82090d 0%,#b8151e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#82090d', endColorstr='#b8151e',GradientType=1 );

    color: #fff;
    text-align: center;
    height: 40px;
    line-height: 40px;

    display: none;

    > div, > span{
        display: inline-block;
        vertical-align: middle;
    }

    > span{
        font-family: @headings-font-family;
        font-weight: @headings-font-weight;
        font-size: 14px;
    }

    .video-top-teaser__btn{
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        background-color: #fff;
        width: 120px;
        height: 24px;
        font-size: 14px;
        line-height: 24px;
        border-radius: 4px;
        color: #C40005;
        margin-left: 30px;
        @media (max-width: 480px) {
            margin-left: 10px;
            width: 100px;
        }
    }

    .video-top-teaser__close{
        display: none !important;
        width: 24px;
        height: 24px;
        background-image: url('@{images}/close-video-modal.svg');
        background-repeat: no-repeat;
        cursor: pointer;
        margin-left: 10px;
        transition: transform .3s;
        &:hover{
            transform: scale(1.1);
        }
    }

    .video-top-teaser__txt{
        &--mobile{
            display: none;
        }

        @media (max-width: 700px) {
            &--pc{
                display: none;
            }
            &--mobile{
                display: inline-block;
                font-size: 12px;
            }
        }
        @media (max-width: 350px) {
            &--mobile{
                display: inline-block;
                font-size: 10px;
            }
        }
    }
}

.page-content.home{
    position: relative;
}
.page-content.home .container.home-slider{
    position: relative;
    display: block;
    width: 100%;
    min-height: 490px;
    margin: auto;

    .home-slider-list{
        min-height: 490px;
    }

    .home-slider__slide{
        outline: none;
        padding: 40px 0;
        box-sizing: border-box;
    }
    @media (max-width: 678px) {
        .home-slider__slide{
            padding-top: 20;
        }
    }

    img{
       display: none;
    }
    .img-link{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 65%;
        width: 46%;
        background-color: transparent;
        opacity: 0.0;
        margin-left: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 40px;
    }
    @media (max-width: 1024px) {
        .img-link{
            max-width: 480px;
            width: 100%;
            margin: 0 auto;
            left: 40px;
            right: 0;
            top: 0;
            margin-top: 200px;
            margin-bottom: 20px;
            height: 47%;
        }
    }
    @media (max-width: 768px) {
        .img-link{
            max-width: 480px;
            width: 100%;
            margin: 0 auto;
            left: 0px;
            right: 0;
            top: 0;
            margin-top: 50%;
            margin-bottom: 20px;
            height: 37%;
        }
    }

    .title{
        font-size: 40px;
        line-height: 48px;
        color: #4F768B;
        text-align: center;
        max-width: 700px;
        margin: auto;
        font-weight: @headings-font-weight;
        font-family: @headings-font-family;
        margin-bottom: 50px;
        > b{
            color: #C80003;
        }

        @media all and (-ms-high-contrast:none)
        {
            font-size: 38px;
        }

    }
    @media (max-width: 768px) {
        .title{
            font-size: 6.7vw;
            line-height: 1.5;
            margin-bottom: 10px;
        }
    }

    .home-slider__slide{
        height: 490px;
        background-repeat: no-repeat;
        background-size: 50% auto; // 70%
        background-position: 0 150px;
        // display: flex;
        // flex-direction: row;
        > .part{
            width: 50%;
            &.left{
                float: left;
            }
            &.right{
                float: right;
            }
        }
        &:after{
            content: '';
            clear: both;
        }

        &.no_title{
            background-position: 0 0;
            > .part.right{
                padding-top: 100px;
            }
        }

        @media (max-width: 1024px) {
            background-image: none !important;
            height: auto;

            &.no_title > .part.right{
                padding-top: 0;
            }

            img{
                display: block;
                width: 100%;
                max-width: 520px;
                height: auto;
                margin: auto;
            }

            > .part{
                width: 100%;
                height: auto;
                &.left{
                    margin-bottom: 25px;
                }
                &.right{
                    position: relative;
                    margin-top: 70px;
                }
                &.left, &.right{
                    float: none;

                }
            }
        }
    }
    .button_sign_text{
        margin-bottom: 7px;
        font-weight: @headings-font-weight;
        font-family: @headings-font-family;
    }
    .home-slider__progress-bar{
        position: absolute;
        width: ~"calc(50% - 15px)";
        right: 15px;
        bottom: 80px;
        z-index: 300;
        display: flex;
        justify-content: center;
        > div{
            width: 62px;
            height: 4px;
            background-color: rgba(79, 118, 139, .24);
            margin: 0 10px;
            cursor: pointer;
            > span{
                display: block;
                width: 0;
                height: 100%;
                background: #C80005;
            }
        }

        &.home-slider--preloading{
            visibility: hidden;
        }

        @media (max-width: 1024px) {
            width: auto;
            margin: auto;
            top: -40px;
            right: 0;
            left: 0;
        }

    }

    #videos-loading{
        background: transparent;
    }

    &--preloading{
        height: 490px;
        overflow: hidden;

        .home-slider-list{
            visibility: hidden;
        }
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait){
        width: 618px;
    }
    @media (min-width: 768px) {
        width: 650px;
    }
    @media (min-width: 870px) {
        width: 750px;
    }
    @media (min-width: 992px) {
        width: 854px;
    }
    @media (min-width: 1100px) {
        width: 954px;
    }
}

.home-slider__arrow{
    position: absolute;
    width: 20px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 100%;
    transition: opacity .5s;
    cursor: pointer;
    top:  ~"calc(50% - 20px)";
    &.prev{
        background-image: url("@{images}/arrow-video-left.svg");
        left: 60px;
    }
    &.next{
        background-image: url("@{images}/arrow-video-right.svg");
        right: 60px;
    }
    &:hover{
        opacity: .8;
    }

    &.home-slider--preloading{
        visibility: hidden;
    }

    @media (max-width: 1024px) {
        display: none !important;
    }
}